<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-card title="قرعه کشی" class="mt-3">
      <b-button
        v-b-toggle.filterBox
        variant="primary"
        class="mb-3 position-absolute"
        style="top:20px; left: 20px;"
        size="sm"
        ><i class="fa fa-bars"></i
      ></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="title">نام و نام خانوادگی</label>
                  <b-form-input
                    id="title"
                    v-model="searchParams.name"
                  ></b-form-input>
                </div>
              </div>

              <div class="col-md-3">
                <b-form-group label=" شماره تماس" label-for="phone-number">
                  <b-form-input
                    id="phone-number"
                    v-model="searchParams.phone_number"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label=" کد" label-for="key">
                  <b-form-input
                    id="key"
                    v-model="searchParams.key"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-3">
                  <b-form-group label="استان">
                <multiselect v-model="selected_province" open-direction="bottom" :options="provinces" label="name"
                  track-by="id" :searchable="true" :close-on-select="true" :show-labels="false"
                  :disabled="disabled">
                </multiselect>
              </b-form-group>
              </div>
            </div>
            <button
              class="btn btn-primary btn-sm float-left"
              type="submit"
              :disabled="disabled"
            >
              جست و جو
            </button>
          </form>
        </b-card>
      </b-collapse>
      <b-table
        responsive
        striped
        hover
        v-if="items.data"
        :fields="table_fields"
        :items="items.data.data"
      >
        <template v-slot:cell(province)="data">{{
          data.item.province.name
        }}</template>
        <template v-slot:cell(created_at)="data">{{
          data.item.created_at | persianDate
        }}</template>
      </b-table>
      <div
        class="alert alert-danger text-right"
        v-if="items.data && items.data.data == ''"
      >
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <pagination
      :limit="2"
      v-if="items.data && !search"
      :data="items.data"
      @pagination-change-page="loadItems"
    >
    </pagination>
    <pagination
      :limit="2"
      v-else-if="items.data && search"
      :data="items.data"
      @pagination-change-page="searchItems"
    >
    </pagination>
  </div>
</template>

<script>
import mixins from "./mixins/mixins";

export default {
  mixins: [mixins],
  data() {
    return {
      url: "/api/admin/lotteries",
      title: "قرعه کشی",
      gifts: [],
      selected_filter_status: "",
      table_fields: [
        {
          key: "id",
          label: "شناسه ",
        },
        {
          key: "name",
          label: "نام و نام خانوادگی",
        },
        {
          key: "phone_number",
          label: "شماره تماس",
        },
        {
          key: "key",
          label: "کد",
        },
        {
          key: "province",
          label: "استان",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
      ],
      provinces: [],
      selected_province: null
    };
  },
  created() {
    this.loadItems();
    this.loadProvinces();
    this.searchParams = {
      name: "",
      phone_number: "",
      key: "",
    };
  },
  methods: {
    loadProvinces() {
      this.$axios
        .get(this.$root.baseUrl + "/api/admin/list/provinces/102?all=true")
        .then((response) => {
          this.provinces = response.data.data;
        });
    },
    searchItems(page = 1) {
      this.disabled = true;
      this.search = true;

      this.$axios
        .get(this.$root.baseUrl + this.url + "?page=" + page, {
          params: {
            search1: this.searchParams.name,
            searchBy1: "name",
            search2: this.searchParams.phone_number,
            searchBy2: "phone_number",
            search3: this.searchParams.key,
            searchBy3: "key",
            search4: this.selected_province ? this.selected_province.id : '',
            searchBy4: "province_id",
          },
        })
        .then((response) => {
          this.search = true;
          this.items = response.data;
        })
        .catch((error) => {
          this.$root.error_notification(error);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
  },
};
</script>
